.card-logo {
  display: inline-block;
  width: 160px;
  height: 100px;
  background-size: 160px 100px;
}

.card-logo.small {
  width: 53.33333333px;
  height: 33.33333333px;
  background-size: 53.33333333px 33.33333333px;
}

.card-logo.large {
  width: 240px;
  height: 150px;
  background-size: 240px 150px;
}

.card-logo.card-logo-visa {
  background-image: url('/assets/images/cards/card-logo-visa.svg');
}

.card-logo.card-logo-mastercard {
  background-image: url('/assets/images/cards/card-logo-mastercard.svg');
}

.card-logo.card-logo-amex {
  background-image: url('/assets/images/cards/card-logo-amex.svg');
}

.card-logo.card-logo-unknown {
  background-image: url('/assets/images/cards/card-logo-unknown.svg');
}

.card-logo-list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
}

.card-logo-list>.card-logo+.card-logo {
  margin-left: 6px;
}
