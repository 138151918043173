@use 'sass:color';
@use './theme-colors.scss' as ad;

/* Light-mode */
body.theme-light {
  background: ad.admefy-color('light') !important;
  color: var(--admefy-secondary) !important;
  border-color: rgba(0, 0, 0, 20%) !important;

  *:fullscreen {
    background-color: ad.admefy-color('light');
  }

  .notification {
    &:hover {
      background-color: ad.admefy-color('hover-gray');
      color: var(--admefy-secondary);
    }
  }

  .channel-box {
    border-color: rgba(0, 0, 0, 20%) !important;
  }

  .reply-comment-wrapper,
  .chat-pane__chat-list--comment .body {
    background-color: ad.admefy-color('chat-light');
    color: var(--admefy-secondary);
  }

  header {
    border-top: 1.5px solid ad.admefy-color('dark-gray');

    @media (max-width: 768px) {
      border: none;
    }

    .bell-icon {
      background-image: url(/assets/header/notifications-dark.svg);
    }

    .cart-icon {
      background-image: url(/assets/header/screens-cart-dark.svg);
    }

    .wallet-icon {
      background-image: url(/assets/header/wallet-dark.svg);
    }

    .home-icon {
      background-image: url(/assets/header/home-dark.svg);
    }

    .explore-button {
      background-image: url(/assets/header/background-toogle-menu.svg);

      span {
        color: ad.admefy-color('light') !important;
      }
    }

    .new-logo {
      background: transparent url('/assets/header/logo-dark-pink.svg') 0% 0%
        no-repeat padding-box;
    }
  }

  .media-box-top {
    color: ad.admefy-color('dark-gray');
  }

  input[type='time']::-webkit-calendar-picker-indicator {
    filter: invert(0%) sepia(90%) saturate(200%) hue-rotate(45deg);
    cursor: pointer;
    margin: 0;
  }

  .datatable-pager {
    a,
    li.disabled a {
      color: var(--admefy-secondary);
    }
  }

  .custom-select-list select,
  ul,
  li,
  h3,
  input,
  h4,
  label,
  em,
  i,
  a,
  span,
  .mat-mdc-icon-button .mat-mdc-button-persistent-ripple,
  mat-label,
  button {
    color: var(--admefy-secondary);
  }

  admefy-creator-box form input,
  ngx-datatable.ngx-datatable.material.admefy-theme,
  .datatable-header-cell,
  .mobile-send-menu .circle,
  .selected-displays-container,
  .selected-displays-submenu,
  .selected-displays,
  selected-displays-container-mobile,
  thead,
  tr,
  table,
  th,
  ng-dropdown-panel,
  ng-select div,
  header nav,
  .mat-mdc-paginator,
  .ng-select div,
  [mat-dialog-title],
  .mat-label,
  .mat-mdc-menu-panel,
  .mat-mdc-select-panel,
  .mat-expansion-panel,
  .mat-calendar-header,
  .mat-date-range-input-inner,
  .mat-calendar-body-label,
  .mat-mdc-autocomplete-panel,
  .mat-calendar,
  .notification-submenu,
  .dropdown-menu,
  .parent-popup,
  .popup-elements button,
  .popup-elements select,
  .admeet-options,
  .admeet-settings,
  .help-menu,
  .card,
  .card-media,
  .card-header,
  .card-body,
  .grdp,
  .bg-filters,
  .btn-previous,
  admefy-send-media-campaign,
  admefy-displays-using-media,
  admefy-dialog-my-medias,
  .dropdown-content-menu,
  .popup-wrapper-admefy,
  .displays-selected-box,
  .event-date,
  .show-info,
  ngb-modal-window .modal-content,
  admefy-dialog-short-url-player,
  admefy-smart-photo-card .popup,
  .chat-pane__chat-box--user,
  .share-admeet .share-menu,
  .cdk-drag.media,
  .mapboxgl-ctrl-geocoder--input,
  admefy-autocomplete-dialog,
  admefy-dialog-big-calendar,
  admefy-media-campaign-schedule .btn,
  admefy-media-campaign-schedule,
  mat-dialog-actions,
  mat-dialog-content,
  .extra,
  .main-menu,
  .extra-options,
  .wallet-widget,
  .card-footer {
    background: ad.admefy-color('light') !important;
    color: var(--admefy-secondary) !important;
  }

  .vjs-button {
    span {
      color: ad.admefy-color('light') !important;
    }
  }

  .mapboxgl-ctrl-geocoder--button,
  .mapboxgl-ctrl-geocoder {
    background: transparent;
  }

  .mapboxgl-ctrl-geocoder--input {
    border-radius: 16px;
  }

  .cards button i,
  .cards span,
  .cards p {
    color: ad.admefy-color('light') !important;
  }

  .video-js .vjs-volume-level {
    background-color: var(--admefy-primary) !important;
  }

  friend-box img-preload {
    background-color: ad.admefy-color('light') !important;
  }

  .panel {
    background: ad.admefy-color('hover-light') !important;
    color: ad.admefy-color('dark') !important;
  }

  .mat-mdc-dialog-surface,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background: transparent !important;
  }

  admefy-input-edit input {
    background-color: ad.admefy-color('hover-gray') !important;
  }

  admefy-map-digital-signage .filters {
    background: ad.admefy-color('light') !important;
    color: var(--admefy-secondary) !important;
  }

  .dialog-steps .mat-mdc-dialog-surface,
  .dialog-steps
    .mat-mdc-dialog-container
    .mdc-dialog__surface
    .dialog-steps
    .mat-mdc-dialog-surface {
    background: ad.admefy-color('light') !important;
  }

  .ng-input input {
    background: transparent !important;
    color: var(--admefy-secondary) !important;
  }

  img-preload {
    background: var(--admefy-secondary);
  }

  .main-menu-trigger,
  .landing-main .grid-container .item h3 {
    color: var(--admefy-secondary) !important;
  }

  .multi-button {
    background: ad.admefy-color('hover-light');
    color: ad.admefy-color('dark');
  }

  .mdc-text-field {
    padding: 0;

    input {
      margin: 0;
      border-radius: 10px;
      text-align: left;
      font: normal normal normal 14px / 16px SF Pro Text;
      letter-spacing: 0.14px;
    }
  }

  .mediaForm input,
  ng-select,
  mat-select,
  .mat-mdc-tab-body-content input,
  admefy-creator-box form input,
  admefy-send-media-campaign form input,
  .cdk-drag.media,
  .mdc-checkbox__background,
  .media-body .media,
  editor,
  admefy-users-autocomplete mat-form-field .mdc-text-field--filled {
    border: 1px solid var(--admefy-secondary);
    color: var(--admefy-secondary);

    &::placeholder {
      color: var(--admefy-secondary);
    }
  }

  .landing-header {
    background: ad.admefy-color('light');
  }

  .notification {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  .mat-mdc-tab-label-container,
  .list-content .display,
  .wallet-box .amount,
  .analytics-box .section {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label,
  .mat-mdc-tab.mdc-tab--active:hover .mdc-tab__text-label,
  mat-tab,
  mat-icon,
  .mat-mdc-tab .mdc-tab__text-label {
    color: ad.admefy-color('dark');
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::before {
    border: none !important;
  }

  form .ng-select div {
    background: transparent !important;
  }

  .ng-select .ng-select-container .ng-value-container .ng-placeholder,
  .mat-input-element::placeholder {
    color: var(--admefy-secondary) !important;
    //z-index: 3;
  }

  .ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: rgba(0, 0, 0, 0.54) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) !important;
    margin-right: 5px;
  }

  .image-text {
    outline: 1px dashed var(--admefy-secondary);
    color: var(--admefy-secondary);
  }

  .mobile-user-menu {
    .background-overlay {
      background: rgb(255 255 255 / 90%) 0% 0% no-repeat padding-box;
    }
  }

  .admeet-translate-language,
  .captions {
    background: rgb(255 255 255 / 90%) 0% 0% no-repeat padding-box;
    color: ad.admefy-color('dark');
  }

  .mdc-checkbox__background {
    border-color: rgba(0, 0, 0, 20%) !important;
  }

  .mat-mdc-icon-button img,
  .mat-mdc-icon-button svg,
  .connect-display img,
  .go-live img,
  .theme-light-filter {
    filter: invert(1);
  }

  .mobile-notifications-trigger,
  .mobile-selected-displays-trigger,
  .selected-displays-container,
  .trigger-mobile,
  .mdc-tab__text-label,
  .responsive-creator-grid .screens,
  .responsive-creator-grid .channels,
  .responsive-creator-grid .subscriptions,
  .responsive-creator-grid .my-user,
  .screens-tab .screens,
  .screens-tab .channels,
  .screens-tab .subscriptions,
  .screens-tab .my-user,
  .my-user-tab .screens,
  .my-user-tab .channels,
  .my-user-tab .subscriptions,
  .my-user-tab .my-user,
  .subscriptions-tab .screens,
  .subscriptions-tab .channels,
  .subscriptions-tab .subscriptions,
  .subscriptions-tab .my-user,
  .channels-tab .screens,
  .channels-tab .channels,
  .channels-tab .subscriptions,
  .channels-tab .my-user,
  .back-btn,
  .share,
  .admeet-options,
  .mat-expansion-panel-header-title,
  .main-menu-drop {
    img {
      filter: invert(1);
    }
  }

  mat-datepicker-toggle {
    svg {
      filter: invert(0) !important;
    }
  }

  admefy-profile .shadow-overlay {
    background: rgba(255, 255, 255, 0.2);
  }

  header .users .dropdown-menu.show {
    .menu-item {
      &:hover {
        border-radius: 16px;
        background: ad.admefy-color('hover-light');

        span {
          color: ad.admefy-color('dark');
        }
      }
    }

    .mat-expansion-panel-body a {
      &:hover {
        span {
          color: var(--admefy-primary);
        }
      }
    }
  }

  .comment-author {
    color: var(--admefy-secondary) !important;
  }

  .data-visualization .card-data,
  .data-campaings .card-campaign {
    background: ad.admefy-color('hover-light');
    color: var(--admefy-secondary);

    .card-view {
      background-image: url('/assets/cards/view-eye-dark.svg');
    }

    .card-profile {
      background-image: url('/assets/cards/user-dark.svg');
    }

    .card-button,
    .campaigns-status .state {
      background: ad.admefy-color('light');
      color: var(--admefy-secondary);
    }
  }

  .exchange-icon {
    i {
      &.exchange {
        background-image: url('/assets/cards/exchange-dark.svg');
      }

      &.euro {
        background-image: url('/assets/cards/euro-dark.svg');
      }
    }
  }

  .qr-info .item,
  .data .generic-card {
    background: ad.admefy-color('hover-light');
    color: var(--admefy-secondary);

    .card-view {
      background-image: url('/assets/cards/view-eye-dark.svg');
    }

    .card-profile {
      background-image: url('/assets/cards/user-dark.svg');
    }

    .card-button,
    .status .state {
      background: ad.admefy-color('light');
      color: var(--admefy-secondary);
    }
  }

  .summary-content {
    &__card {
      background-color: var(--admefy-secondary);
    }
  }

  // MODO LIGHT

  .display-list-popup {
    .close {
      background-image: url('/assets/cards/close-list.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    .drop-down {
      background-image: url('/assets/cards/down.svg');
    }

    .plus {
      background-image: url('/assets/cards/add-list.svg');
    }
  }

  .subscribe-mobile,
  .subscription-button {
    i {
      &.unsuscribe-mobile {
        background-image: url('/assets/responsive/subs-dark.svg');
      }

      &.suscribe-mobile {
        background-image: url('/assets/responsive/subs-green-dark.svg');
      }
    }

    &:hover {
      i {
        background-image: url('/assets/responsive/subs-dark.svg');
      }
    }
  }

  .edit-dvr-btn {
    i {
      background-image: url('/assets/cards/edit-dark.svg');
    }

    &:hover {
      i {
        background-image: url('/assets/cards/edit-green.svg');
      }
    }
  }

  .save-dvr-btn {
    i {
      background-image: url('/assets/cards/save-dark.svg');
    }

    &:hover {
      i {
        background-image: url('/assets/cards/save-green.svg');
      }
    }
  }

  .save-btn {
    i {
      background-image: url('/assets/lists/save-dark.svg');
    }

    &:hover {
      i {
        background-image: url('/assets/lists/save-green.svg');
      }
    }
  }

  .recharge-btn {
    i {
      background-image: url('/assets/lists/recharge-dark.svg');
    }

    &:hover {
      i {
        background-image: url('/assets/lists/recharge-green.svg');
      }
    }
  }

  .follower-button {
    i {
      &.unfollow-mobile {
        background-image: url('/assets/responsive/unfollow-dark.svg');
      }

      &.follow-mobile {
        background-image: url('/assets/responsive/follow-green-dark.svg');
      }
    }

    &:hover {
      i {
        background-image: url('/assets/responsive/unfollow-dark.svg');
      }
    }
  }

  .mobile-profile {
    .send-content {
      i {
        &.send-mobile {
          background-image: url('/assets/responsive/send-dark.svg');
        }
      }
    }
  }

  .user-links {
    i {
      &.info-mobile {
        background-image: url('/assets/responsive/info-dark.svg');
      }

      &.records-mobile {
        background-image: url('/assets/responsive/records-dark.svg');
      }
    }
  }

  .media-box-top i,
  .media-card-icon i,
  .footer-media .media-type i,
  .sender-info .media i {
    &.message {
      background-image: url('/assets/medias/message-green-dark.svg');
    }

    &.my-medias {
      background-image: url('/assets/medias/my-medias-green-dark.svg');
    }

    &.video {
      background-image: url('/assets/medias/video-green-dark.svg');
    }

    &.social {
      background-image: url('/assets/medias/social-green-dark.svg');
    }

    &.stream {
      background-image: url('/assets/medias/stream-green-dark.svg');
    }

    &.direct {
      background-image: url('/assets/medias/direct-green-dark.svg');
    }

    &.direct_webcam {
      background-image: url('/assets/medias/admeet-green-dark.svg');
    }

    &.audio {
      background-image: url('/assets/medias/audio-green-dark.svg');
    }

    &.web {
      background-image: url('/assets/medias/web-green-dark.svg');
    }

    &.pdf {
      background-image: url('/assets/medias/pdf-green-dark.svg');
    }

    &.image {
      background-image: url('/assets/medias/image-green-dark.svg');
    }

    &:hover {
      .message {
        background-image: url('/assets/medias/message-white.svg');
      }

      .my-medias {
        background-image: url('/assets/medias/my-medias-white.svg');
      }

      .video {
        background-image: url('/assets/medias/video-white.svg');
      }

      .social {
        background-image: url('/assets/medias/social-white.svg');
      }

      .stream {
        background-image: url('/assets/medias/stream-white.svg');
      }

      .direct {
        background-image: url('/assets/medias/direct-white.svg');
      }

      .direct_webcam {
        background-image: url('/assets/medias/admeet-white.svg');
      }

      .audio {
        background-image: url('/assets/medias/audio-white.svg');
      }

      .web {
        background-image: url('/assets/medias/web-white.svg');
      }

      .pdf {
        background-image: url('/assets/medias/pdf-white.svg');
      }

      .image {
        background-image: url('/assets/medias/image-white.svg');
      }
    }
  }

  .send-media-buttons {
    button {
      .message {
        background-image: url('/assets/medias/message-green-dark.svg');
      }

      .my-medias {
        background-image: url('/assets/medias/my-medias-green-dark.svg');
      }

      .video {
        background-image: url('/assets/medias/video-green-dark.svg');
      }

      .social {
        background-image: url('/assets/medias/social-green-dark.svg');
      }

      .stream {
        background-image: url('/assets/medias/stream-green-dark.svg');
      }

      .direct {
        background-image: url('/assets/medias/direct-green-dark.svg');
      }

      .direct_webcam {
        background-image: url('/assets/medias/admeet-green-dark.svg');
      }

      .audio {
        background-image: url('/assets/medias/audio-green-dark.svg');
      }

      .web {
        background-image: url('/assets/medias/web-green-dark.svg');
      }

      .pdf {
        background-image: url('/assets/medias/pdf-green-dark.svg');
      }

      .image {
        background-image: url('/assets/medias/image-green-dark.svg');
      }

      &:hover {
        .message {
          background-image: url('/assets/medias/message-white.svg');
        }

        .my-medias {
          background-image: url('/assets/medias/my-medias-white.svg');
        }

        .video {
          background-image: url('/assets/medias/video-white.svg');
        }

        .social {
          background-image: url('/assets/medias/social-white.svg');
        }

        .stream {
          background-image: url('/assets/medias/stream-white.svg');
        }

        .direct {
          background-image: url('/assets/medias/direct-white.svg');
        }

        .direct_webcam {
          background-image: url('/assets/medias/admeet-white.svg');
        }

        .audio {
          background-image: url('/assets/medias/audio-white.svg');
        }

        .web {
          background-image: url('/assets/medias/web-white.svg');
        }

        .pdf {
          background-image: url('/assets/medias/pdf-white.svg');
        }

        .image {
          background-image: url('/assets/medias/image-white.svg');
        }
      }

      &.active {
        i.message {
          background-image: url('/assets/medias/message-white.svg');
        }

        i.video {
          background-image: url('/assets/medias/video-white.svg');
        }

        i.social {
          background-image: url('/assets/medias/social-white.svg');
        }

        i.stream {
          background-image: url('/assets/medias/stream-white.svg');
        }

        i.direct {
          background-image: url('/assets/medias/direct-white.svg');
        }

        i.direct_webcam {
          background-image: url('/assets/medias/admeet-white.svg');
        }

        i.audio {
          background-image: url('/assets/medias/audio-white.svg');
        }

        i.web {
          background-image: url('/assets/medias/web-white.svg');
        }

        i.pdf {
          background-image: url('/assets/medias/pdf-white.svg');
        }

        i.image {
          background-image: url('/assets/medias/image-white.svg');
        }
      }
    }
  }

  .share-meet-link {
    background-image: url('/assets/admeet/share-admeet-dark.svg');
  }

  .disconnect {
    background-image: url('/assets/admeet/disconnect.svg');
  }

  .menu-meet {
    background-image: url('/assets/admeet/menu-green.svg');
  }

  .menu-off {
    background-image: url('/assets/admeet/menu-green-dark.svg');
  }

  .share-meet {
    background-image: url('/assets/admeet/share-green.svg');
  }

  .share-off {
    background-image: url('/assets/admeet/share-green-dark.svg');
  }

  .captions-on {
    background-image: url('/assets/admeet/captions-green.svg');
  }

  .captions-off {
    background-image: url('/assets/admeet/captions-green-dark.svg');
  }

  .camera-meet {
    background-image: url('/assets/admeet/camera-green-dark.svg');
  }

  .camera-off {
    background-image: url('/assets/admeet/camera-off.svg');
  }

  .micro-meet {
    background-image: url('/assets/admeet/micro-green-dark.svg');
  }

  .micro-off {
    background-image: url('/assets/admeet/micro-off.svg');
  }

  .chat-pane {
    .chat-reactions {
      background-image: url('/assets/chat/reactions-dark.svg');
    }

    .chat-emotes {
      background-image: url('/assets/chat/emotes-dark.svg');
    }

    .chat-stickers {
      background-image: url('/assets/chat/stickers-dark.svg');
    }

    .chat-gifs {
      background-image: url('/assets/chat/gifs-dark.svg');
    }
  }

  .chat-menu-buttons {
    .reactions-chat {
      background-image: url('/assets/chat/reactions-chat-green-dark.svg');
    }

    .dots-chat {
      background-image: url('/assets/chat/dots-chat-green-dark.svg');
    }

    .info-chat {
      background-image: url('/assets/chat/info-chat-green-dark.svg');
    }
  }

  .display-details-info {
    .item {
      .resolution {
        background-image: url('/assets/cards/resolution-dark.svg');
      }

      .views {
        background-image: url('/assets/cards/views-dark.svg');
      }

      .time {
        background-image: url('/assets/cards/time-dark.svg');
      }

      .geo {
        background-image: url('/assets/cards/geo-dark.svg');
      }
    }
  }

  .popup-options {
    .item {
      .time {
        background-image: url('/assets/cards/time-dark.svg');
      }

      .allowed-medias {
        background-image: url('/assets/cards/allowed-medias-dark.svg');
      }
    }
  }

  .barca-logo {
    i {
      background-image: url('/assets/header/barca-hub-dark.svg');
    }
  }

  .main-menu .descripcion {
    color: ad.admefy-color('card-header-light');
  }

  .allowed-media-item {
    background-color: ad.admefy-color('chat-light');
    color: var(--admefy-secondary);
  }

  .main-menu-drop {
    .menu {
      .menu-item,
      a {
        color: var(--admefy-secondary);

        /* T3 - Regular */
        font-family:
          SF Pro Text,
          SF Pro Text;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;

        /* 23.8px */
        i {
          width: 30px;
          height: 30px;
          display: inline-flex;
          padding: 8px 20px;
          justify-content: center;
          align-items: center;
          gap: 12px;
          background-repeat: no-repeat;
          background-position: center center;

          &.profile {
            background-image: url('/assets/menu/profile-dark.svg');
          }

          &.admin {
            background-image: url('/assets/menu/admin-dark.svg');
          }

          &.channels {
            background-image: url('/assets/menu/channels-dark.svg');
          }

          &.contact {
            background-image: url('/assets/menu/contact-dark.svg');
          }

          &.info {
            background-image: url('/assets/menu/info-dark.svg');
          }

          &.links {
            background-image: url('/assets/menu/links-dark.svg');
          }

          &.login {
            background-image: url('/assets/menu/login-dark.svg');
          }

          &.logout {
            background-image: url('/assets/menu/logout-dark.svg');
          }

          &.moon {
            background-image: url('/assets/menu/moon-dark.svg');
          }

          &.sun {
            background-image: url('/assets/menu/sun-dark.svg');
          }

          &.policy {
            background-image: url('/assets/menu/policy-dark.svg');
          }
        }

        &:active {
          border-radius: 16px;
          background: var(--admefy-secondary);
          color: var(--admefy-primary);
        }

        &:hover {
          border-radius: 16px;
          justify-content: center;
          align-items: center;

          i {
            &.profile {
              background-image: url('/assets/menu/profile-green-dark.svg');
            }

            &.admin {
              background-image: url('/assets/menu/admin-green-dark.svg');
            }

            &.channels {
              background-image: url('/assets/menu/channels-green-dark.svg');
            }

            &.contact {
              background-image: url('/assets/menu/contact-green-dark.svg');
            }

            &.info {
              background-image: url('/assets/menu/info-green-dark.svg');
            }

            &.links {
              background-image: url('/assets/menu/links-green-dark.svg');
            }

            &.login {
              background-image: url('/assets/menu/login-green-dark.svg');
            }

            &.logout {
              background-image: url('/assets/menu/logout-red-dark.svg');
            }

            &.moon {
              background-image: url('/assets/menu/moon-green-dark.svg');
            }

            &.sun {
              background-image: url('/assets/menu/sun-green-dark.svg');
            }

            &.policy {
              background-image: url('/assets/menu/policy-dark.svg');
            }
          }
        }
      }

      .active-link {
        color: var(--admefy-primary) !important;

        i {
          &.profile {
            background-image: url('/assets/menu/profile-green-dark.svg');
          }

          &.admin {
            background-image: url('/assets/menu/admin-green-dark.svg');
          }

          &.channels {
            background-image: url('/assets/menu/channels-green-dark.svg');
          }

          &.contact {
            background-image: url('/assets/menu/contact-green-dark.svg');
          }

          &.info {
            background-image: url('/assets/menu/info-green-dark.svg');
          }

          &.links {
            background-image: url('/assets/menu/links-green-dark.svg');
          }

          &.logout {
            background-image: url('/assets/menu/logout-red-dark.svg');
          }

          &.signup {
            background-image: url('/assets/menu/signup-green-dark.svg');
          }

          &.login {
            background-image: url('/assets/menu/login-green-dark.svg');
          }

          &.moon {
            background-image: url('/assets/menu/moon-green-dark.svg');
          }

          &.sun {
            background-image: url('/assets/menu/sun-green-dark.svg');
          }

          &.policy {
            background-image: url('/assets/menu/policy-dark.svg');
          }
        }
      }
    }
  }

  .select-all-displays {
    i {
      width: 20px;
      height: 20px;
      background-position: center;
      background-repeat: no-repeat;

      &.check {
        background-image: url('/assets/map/check-dark.svg') !important;
      }

      &.remove {
        background-image: url('/assets/map/remove-dark.svg') !important;
      }
    }
  }

  .filters {
    i {
      width: 20px;
      height: 20px;
      background-position: center;
      background-repeat: no-repeat;

      &.filter {
        background-image: url('/assets/map/filter-dark.svg') !important;
      }
    }
  }

  .world {
    i {
      width: 20px;
      height: 20px;
      background-position: center;
      background-repeat: no-repeat;

      &.mundi {
        background-image: url('/assets/map/world-dark.svg') !important;
      }
    }
  }

  .search-wrapper {
    input.from[type='date' i]::-webkit-calendar-picker-indicator {
      background-image: url('/assets/map/from-date-dark.svg') !important;
    }

    input.to[type='date' i]::-webkit-calendar-picker-indicator {
      background-image: url('/assets/map/to-date-dark.svg') !important;
    }

    input.category,
    input.location {
      background-image: url('/assets/map/search-dark.svg') !important;
    }

    i {
      &.from-date {
        background-image: url('/assets/map/from-date-dark.svg');
      }

      &.to-date {
        background-image: url('/assets/map/to-date-dark.svg');
      }
    }
  }

  .open-map {
    i {
      &.map {
        background-image: url('/assets/sidebar/map-green-dark.svg');
      }
    }
  }

  .responsive-creator-grid {
    .channels-icon {
      background-image: url('/assets/responsive/channel-dark.svg');
    }

    .screens-icon {
      background-image: url('/assets/responsive/screen-dark.svg');
    }

    .subscriptions-icon {
      background-image: url('/assets/responsive/subscription-dark.svg');
    }

    .my-user-icon {
      background-image: url('/assets/responsive/perfil-dark.svg');
    }

    .reels-icon {
      background-image: url('/assets/responsive/reels-dark.svg');
    }

    .social-icon {
      background-image: url('/assets/responsive/social-dark.svg');
    }
  }

  .channels-tab,
  .screens-tab,
  .subscriptions-tab,
  .my-user-tab {
    .channels-icon {
      background-image: url('/assets/responsive/channel-dark.svg');
    }

    .screens-icon {
      background-image: url('/assets/responsive/screen-dark.svg');
    }

    .subscriptions-icon {
      background-image: url('/assets/responsive/subscription-dark.svg');
    }

    .my-user-icon {
      background-image: url('/assets/responsive/perfil-dark.svg');
    }

    .reels-icon {
      background-image: url('/assets/responsive/reels-dark.svg');
    }

    .social-icon {
      background-image: url('/assets/responsive/social-dark.svg');
    }
  }

  .btn-gray-mode {
    background: ad.admefy-color('hover-light') !important;

    i {
      width: 30px;
      height: 30px;
      display: inline-flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      background-repeat: no-repeat;
      background-position: center center;

      &.qr-analytics {
        background-image: url('/assets/sidebar/qr-analytics-green-dark.svg');
      }

      &.register {
        background-image: url('/assets/sidebar/register-green-dark.svg');
      }

      &.register-channel {
        background-image: url('/assets/sidebar/register-channel-green-dark.svg');
      }

      &.send {
        background-image: url('/assets/sidebar/send-green-dark.svg');
      }

      &.display-lists {
        background-image: url('/assets/sidebar/display-lists-green-dark.svg');
      }

      &.wallet {
        background-image: url('/assets/sidebar/wallet-green-dark.svg');
      }

      &.reactions {
        background-image: url('/assets/sidebar/reactions-green-dark.svg');
      }

      &.next {
        background-image: url('/assets/cards/next-green-dark.svg');
      }
    }
  }

  mat-menu,
  .main-menu-drop,
  sidebar-creator-responsive,
  sidebar-creator {
    .menu {
      a {
        i {
          width: 30px;
          height: 30px;
          display: inline-flex;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          gap: 12px;
          background-repeat: no-repeat;
          background-position: center center;

          &.send {
            background-image: url('/assets/sidebar/send-dark.svg');
          }

          &.map {
            background-image: url('/assets/sidebar/map-dark.svg');
          }

          &.register {
            background-image: url('/assets/sidebar/register-dark.svg');
          }

          &.screens {
            background-image: url('/assets/sidebar/screens-dark.svg');
          }

          &.campaigns {
            background-image: url('/assets/sidebar/campaigns-dark.svg');
          }

          &.earnings {
            background-image: url('/assets/sidebar/earnings-dark.svg');
          }

          &.summary {
            background-image: url('/assets/sidebar/summary-dark.svg');
          }

          &.info-view {
            background-image: url('/assets/sidebar/info-view-dark.svg');
          }

          &.ref-screens {
            background-image: url('/assets/sidebar/ref-screens-dark.svg');
          }

          &.ref-users {
            background-image: url('/assets/sidebar/ref-users-dark.svg');
          }

          &.content {
            background-image: url('/assets/sidebar/content-dark.svg');
          }

          &.display-lists {
            background-image: url('/assets/sidebar/display-lists-dark.svg');
          }

          &.wallet {
            background-image: url('/assets/sidebar/wallet-dark.svg');
          }

          &.qr-analytics {
            background-image: url('/assets/sidebar/qr-analytics-dark.svg');
          }

          &.my-subs {
            background-image: url('/assets/sidebar/my-subs-dark.svg');
          }

          &.subscriptors {
            background-image: url('/assets/sidebar/subscriptors-dark.svg');
          }

          &.reactions {
            background-image: url('/assets/sidebar/reactions-dark.svg');
          }

          &.notifications {
            background-image: url('/assets/sidebar/notifications-dark.svg');
          }

          &.developer {
            background-image: url('/assets/sidebar/developer-dark.svg');
          }

          &.help {
            background-image: url('/assets/sidebar/help-dark.svg');
          }
        }

        &:active {
          border-radius: 16px;
          background: var(--admefy-secondary);

          span {
            color: var(--admefy-primary);
          }
        }

        &:hover {
          border-radius: 16px;
          background: ad.admefy-color('hover-light');
          display: inline-flex;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          gap: 12px;

          i {
            &.send {
              background-image: url('/assets/sidebar/send-green-dark.svg');
            }

            &.map {
              background-image: url('/assets/sidebar/map-green-dark.svg');
            }

            &.register {
              background-image: url('/assets/sidebar/register-green-dark.svg');
            }

            &.screens {
              background-image: url('/assets/sidebar/screens-green-dark.svg');
            }

            &.campaigns {
              background-image: url('/assets/sidebar/campaigns-green-dark.svg');
            }

            &.earnings {
              background-image: url('/assets/sidebar/earnings-green-dark.svg');
            }

            &.summary {
              background-image: url('/assets/sidebar/summary-green-dark.svg');
            }

            &.info-view {
              background-image: url('/assets/sidebar/info-view-green-dark.svg');
            }

            &.ref-screens {
              background-image: url('/assets/sidebar/ref-screens-green-dark.svg');
            }

            &.ref-users {
              background-image: url('/assets/sidebar/ref-users-green-dark.svg');
            }

            &.content {
              background-image: url('/assets/sidebar/content-green-dark.svg');
            }

            &.display-lists {
              background-image: url('/assets/sidebar/display-lists-green-dark.svg');
            }

            &.wallet {
              background-image: url('/assets/sidebar/wallet-green-dark.svg');
            }

            &.qr-analytics {
              background-image: url('/assets/sidebar/qr-analytics-green-dark.svg');
            }

            &.my-subs {
              background-image: url('/assets/sidebar/my-subs-green-dark.svg');
            }

            &.subscriptors {
              background-image: url('/assets/sidebar/subscriptors-green-dark.svg');
            }

            &.reactions {
              background-image: url('/assets/sidebar/reactions-green-dark.svg');
            }

            &.notifications {
              background-image: url('/assets/sidebar/notifications-green-dark.svg');
            }

            &.developer {
              background-image: url('/assets/sidebar/developer-green-dark.svg');
            }
          }

          span {
            color: ad.admefy-color('dark');
          }
        }
      }

      .active-link {
        background: var(--admefy-secondary);
        border-radius: 16px;

        span {
          color: var(--admefy-primary) !important;
        }

        i {
          &.send {
            background-image: url('/assets/sidebar/send-green.svg');
          }

          &.map {
            background-image: url('/assets/sidebar/map-green.svg');
          }

          &.register {
            background-image: url('/assets/sidebar/register-green.svg');
          }

          &.screens {
            background-image: url('/assets/sidebar/screens-green.svg');
          }

          &.campaigns {
            background-image: url('/assets/sidebar/campaigns-green.svg');
          }

          &.earnings {
            background-image: url('/assets/sidebar/earnings-green.svg');
          }

          &.summary {
            background-image: url('/assets/sidebar/summary-green.svg');
          }

          &.info-view {
            background-image: url('/assets/sidebar/info-view-green.svg');
          }

          &.ref-screens {
            background-image: url('/assets/sidebar/ref-screens-green.svg');
          }

          &.ref-users {
            background-image: url('/assets/sidebar/ref-users-green.svg');
          }

          &.content {
            background-image: url('/assets/sidebar/content-green.svg');
          }

          &.display-lists {
            background-image: url('/assets/sidebar/display-lists-green.svg');
          }

          &.wallet {
            background-image: url('/assets/sidebar/wallet-green.svg');
          }

          &.qr-analytics {
            background-image: url('/assets/sidebar/qr-analytics-green.svg');
          }

          &.my-subs {
            background-image: url('/assets/sidebar/my-subs-green.svg');
          }

          &.subscriptors {
            background-image: url('/assets/sidebar/subscriptors-green.svg');
          }

          &.reactions {
            background-image: url('/assets/sidebar/reactions-green.svg');
          }

          &.notifications {
            background-image: url('/assets/sidebar/notifications-green.svg');
          }

          &.developer {
            background-image: url('/assets/sidebar/developer-green.svg');
          }
        }
      }
    }
  }

  .screens-item {
    i {
      background-image: url('/assets/sidebar/screens-dark.svg');
    }
  }

  .box-image {
    .send {
      background-image: url('/assets/creator/send-green-dark.svg');
    }

    .summary {
      background-image: url('/assets/creator/summary-green-dark.svg');
    }

    .register {
      background-image: url('/assets/creator/register-green-dark.svg');
    }

    .earnings {
      background-image: url('/assets/creator/earnings-green-dark.svg');
    }
  }

  .context-submenu-points {
    background-image: url('/assets/feeds/three-points-dark.svg');
  }

  .btn-reactions {
    i {
      background-image: url('/assets/images/plus-white.svg');
    }
  }

  .add-card,
  .add-plus,
  .follow-button {
    i {
      background-image: url('/assets/images/plus-dark.svg');
    }

    &:hover {
      i {
        background-image: url('/assets/images/plus-green-dark.svg');
      }
    }
  }

  .info-scanner {
    i {
      background-image: url('/assets/register/register-points-dark.svg');
    }
  }

  .send-media-buttons button {
    &.active {
      background-color: var(--admefy-primary) !important;
      color: ad.admefy-color('light') !important;
    }
  }

  .generic-card,
  .card-campaign,
  .channel-box {
    .box-header {
      background-color: ad.admefy-color('hover-light');
    }

    .info-full {
      background-color: ad.admefy-color('light');
    }

    .data-padding,
    .data-card,
    .info-channel-box {
      background-color: ad.admefy-color('hover-light');
    }

    .info-grid-item {
      background-color: ad.admefy-color('light');
    }
  }

  .display-settings-card {
    background-color: ad.admefy-color('hover-light');
  }

  .btn-rotate {
    .rotate-right {
      background-image: url('/assets/settings/right-dark.svg');
    }
    .rotate-left {
      background-image: url('/assets/settings/left-dark.svg');
    }
  }

  .edit-icon {
    i {
      background-image: url('/assets/settings/edit-dark.svg');
    }
  }

  .trash-icon {
    i {
      background-image: url('/assets/settings/trash-light.svg');
    }
  }

  .screen-card-buttons {
    i.send {
      background-image: url('/assets/screen-card/send-light.svg');
    }
    i.settings {
      background-image: url('/assets/screen-card/settings-light.svg');
    }
    i.playlist {
      background-image: url('/assets/screen-card/playlist-light.svg');
    }
    i.movements {
      background-image: url('/assets/screen-card/movements-light.svg');
    }
    i.visualization {
      background-image: url('/assets/screen-card/visualization-light.svg');
    }
  }

  .btn-common {
    background-color: ad.admefy-color('light');
    span {
      color: var(--admefy-carbon);
    }

    i {
      background-image: url('/assets/settings/refresh-dark.svg');

      &.rotating {
        @keyframes rotating {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        animation: rotating 1s linear infinite;
      }
    }
  }

  admefy-autocomplete {
    img.search-i {
      margin: 7px 10px;
      filter: invert(1);
    }

    .mat-mdc-form-field-focus-overlay {
      background: transparent;
    }
  }

  .explore-menu-item,
  .context-menu .close-btn,
  .send-media-buttons,
  admefy-search,
  a.notifications,
  #share-button,
  #captions-button,
  .overlay-effect h4,
  .comment,
  .edit,
  .mobile-user-menu {
    img {
      filter: invert(1);
    }
  }

  .share-button,
  share-button button {
    background: var(--admefy-secondary) !important;
    color: ad.admefy-color('light') !important;
  }

  .dropdown-content-menu {
    ul {
      li {
        a {
          &:hover {
            background: ad.admefy-color('chat-light') !important;
            color: ad.admefy-color('dark') !important;

            span {
              color: ad.admefy-color('dark') !important;
            }
          }
        }
      }
    }
  }

  .downloads-grid {
    .item {
      background: var(--admefy-secondary);

      a,
      span {
        color: ad.admefy-color('light') !important;
      }
    }
  }

  .mat-expansion-indicator,
  #mic-icon {
    filter: invert(1);
  }

  .faq {
    .mat-expansion-indicator {
      filter: none;
      width: 28px;
      height: 28px;
    }

    mat-expansion-panel-header .mat-expansion-indicator::after {
      padding: 0;
      border: 0;
      width: 28px;
      height: 28px;
      content: url('/assets/landing-main/close.svg');
    }

    mat-expansion-panel-header.mat-expanded .mat-expansion-indicator::after {
      padding: 0;
      border: 0;
      width: 28px;
      height: 28px;
      content: url('/assets/landing-main/plus.svg');
    }

    .mat-expanded {
      background: #f1f0f2 !important;
    }
  }

  share-button {
    button {
      color: ad.admefy-color('light');
    }
  }

  header .users .dropdown-menu.show,
  .recommendations,
  .next-events,
  .highlighted,
  .profile-public-send-content,
  admefy-profile-public-user-info,
  admefy-creator-box,
  admefy-media-feed-card,
  admefy-edit-profile .profile,
  .selected-displays-submenu,
  .notification-submenu,
  .item-display-list,
  .list-content-header,
  .new-screens,
  .search-box,
  .table-custom-box,
  .video-directs,
  .emailVerify,
  .request-card,
  .show-info .options,
  .contact-options .contact-box,
  .follow-card,
  .about-card,
  .wallet-card,
  friend-box,
  header {
    box-shadow: 0px 0px 14px 0px ad.admefy-color('box-light') !important;
  }

  admefy-creator-box {
    @media (max-width: 768px) {
      box-shadow: none !important;
    }
  }

  .upload-field,
  .filter-button {
    border: 1px solid var(--admefy-secondary);
    color: var(--admefy-secondary);
  }

  .search-box {
    &::-webkit-input-placeholder {
      color: var(--admefy-secondary);
    }
  }

  .image-text {
    span {
      color: #a7a3ad !important;
    }
  }

  mat-form-field {
    .mat-input-element {
      caret-color: ad.admefy-color('dark');
    }

    .mat-mdc-text-field-wrapper {
      background-color: transparent !important;
    }

    .mdc-text-field__input {
      color: ad.admefy-color('dark') !important;
    }
  }

  .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: ad.admefy-color('dark') !important;
  }

  .admefy-search-box-autocomplete,
  .admefy-custom-autocomplete {
    &,
    mat-option {
      background: ad.admefy-color('light') !important;
      color: ad.admefy-color('dark') !important;

      .mdc-list-item__primary-text {
        color: inherit;
        display: block;
        width: 100%;
      }

      &:hover:not(.mat-option-disabled),
      &:focus:not(.mat-option-disabled) {
        background: color.adjust(
          $color: ad.adscreen-color('primary'),
          $lightness: -6%
        );
      }

      &.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
        background: color.adjust(
          $color: ad.adscreen-color('primary'),
          $lightness: -9%
        );
      }
    }
  }

  .month {
    time {
      color: ad.admefy-color('dark');
    }
  }

  .ngx-datatable.material .datatable-header {
    border: none;
  }

  .send-media-buttons button {
    background-color: ad.admefy-color('chat-light');
    color: var(--admefy-secondary);
  }

  .available-display-list {
    background-color: ad.admefy-color('light');
    color: ad.admefy-color('dark');
  }

  .datatable-body {
    overflow-x: hidden;

    .datatable-row-wrapper {
      &:nth-child(odd) {
        background-color: ad.admefy-color('hover-light');
        border-radius: 18px;
      }

      &:nth-child(even) {
        background-color: ad.admefy-color('light');
        border-radius: 18px;
      }

      .datatable-body-row {
        > div {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .datatable-body-cell {
          color: ad.admefy-color('dark');
          text-align: center;
          font-family: SF Pro Text;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.16px;
        }
      }
    }
  }

  .recommendations {
    .image {
      img {
        border: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
  }

  admefy-info {
    button,
    article,
    strong,
    span,
    h1,
    li,
    a,
    p {
      color: #ffffff !important;
    }

    .grdp {
      background: ad.admefy-color('light') !important;
      color: ad.admefy-color('dark') !important;

      a {
        color: ad.admefy-color('red') !important;
      }

      strong,
      p {
        color: ad.admefy-color('dark') !important;
      }
    }
  }

  .plyr__controls {
    button {
      color: ad.admefy-color('light') !important;
    }
  }

  admefy-chat .chat-pane__chat-box--user {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-shadow: 0px 0px 14px 0px ad.admefy-color('box-light') !important;
  }

  .parent-popup {
    background: #f9fafb !important;

    span,
    .display-name,
    .display-location,
    .cat,
    .title,
    .description,
    .price {
      color: var(--admefy-secondary) !important;
    }
  }

  .display-list-popup {
    .displays,
    .lists .list {
      background: #e8ebee;
    }
  }

  .create-list,
  .display-list-popup {
    background: #f9fafb !important;
  }

  .header-profile-brand {
    .user-info {
      background: rgba(255, 255, 255, 0.8);
    }
  }

  .driver-popover.driverjs-theme {
    background-color: var(--admefy-primary);
    color: ad.admefy-color('dark-gray');
  }

  .driver-popover.driverjs-theme .driver-popover-title {
    font-size: 20px;
  }

  .driver-popover.driverjs-theme .driver-popover-title,
  .driver-popover.driverjs-theme .driver-popover-description,
  .driver-popover.driverjs-theme .driver-popover-progress-text {
    color: ad.admefy-color('dark-gray');
  }

  #driver-popover-title {
    border: none !important;
    box-shadow: none !important;
  }

  .driver-popover.driverjs-theme button {
    flex: 1;
    text-align: center;
    background-color: transparent;
    color: ad.admefy-color('dark-gray');
    border-color: ad.admefy-color('dark-gray');
    text-shadow: none;
    font-size: 14px;
    border-radius: 6px;
    margin: 10px;
  }

  .driver-popover.driverjs-theme .driver-popover-navigation-btns {
    justify-content: space-between;
  }

  .driver-popover.driverjs-theme .driver-popover-close-btn {
    color: ad.admefy-color('dark-gray');
    border: none;
  }

  .driver-popover.driverjs-theme
    .driver-popover-arrow-side-left.driver-popover-arrow {
    border-left-color: var(--admefy-primary);
  }

  .driver-popover.driverjs-theme
    .driver-popover-arrow-side-right.driver-popover-arrow {
    border-right-color: var(--admefy-primary);
  }

  .driver-popover.driverjs-theme
    .driver-popover-arrow-side-top.driver-popover-arrow {
    border-top-color: var(--admefy-primary);
  }

  .driver-popover.driverjs-theme
    .driver-popover-arrow-side-bottom.driver-popover-arrow {
    border-bottom-color: var(--admefy-primary);
  }

  .show-reactions,
  .wallet-alert {
    background-color: rgba(217, 217, 218, 0.9);
  }

  .summary-dates-item .range .from,
  span.bold-text {
    color: var(--admefy-secondary) !important;
  }

  span.normal-text {
    color: ad.admefy-color('placeholder-light') !important;
  }

  admefy-info-map-colors,
  admefy-map-digital-signage .search-wrapper,
  admefy-dialog-dates-campaign-schedule,
  admefy-dates-campaign-schedule table {
    background-color: ad.admefy-color('light');
    color: var(--admefy-secondary);
  }

  admefy-map-digital-signage .search-wrapper input,
  .select-range-dates {
    border: 1px solid var(--admefy-secondary);
    color: var(--admefy-secondary);
  }

  .filter-button {
    span {
      color: var(--admefy-secondary) !important;
    }
  }

  @media (max-width: 768px) {
    admefy-sidebar-list {
      .sidebar-content {
        background: transparent !important;
      }

      background-color: transparent !important;
    }
  }
}
