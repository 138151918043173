@use './theme-dark.scss';
@use './theme-light.scss';

:root {
  --deg-1: linear-gradient(
    120deg,
    rgba(231, 36, 110, 1) 0%,
    rgba(63, 86, 178, 1) 100%
  );
  --deg-2: linear-gradient(
    120deg,
    rgba(54, 187, 224, 1) 0%,
    rgba(63, 86, 178, 1) 100%
  );
  --deg-3: linear-gradient(
    120deg,
    rgba(231, 36, 110, 1) 0%,
    rgba(54, 187, 224, 1) 100%
  );

  --admefy-primary: #37d39a;
  --admefy-secondary: #374151;
  --admefy-carbon: #374151;
  --admefy-carbon-light: #6c7481;

  --mdc-form-field-label-text-color: white !important;
  --mdc-outlined-text-field-input-text-color: white;
  --mdc-filled-text-field-label-text-color: white;
  --mdc-filled-text-field-input-text-color: white;
  --mat-select-placeholder-text-color: white;
  --mat-select-enabled-trigger-text-color: white;
  --ng-progress-color: var(--admefy-primary);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--admefy-primary);
}

html .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: white;
  --mdc-form-field-label-text-color: white !important;
  --mat-icon-button-state-layer-color: white;
}

::selection {
  color: white;
  background: var(--admefy-primary);
}
